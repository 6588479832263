<template>
  <div>
    <v-row>

      <v-col cols="12">
        <v-fade-transition>
          <div v-if="id_causa === 1 || id_causa === 2">
            <v-alert
                text
                type="success"
                :icon="false"
            >
              <div class="d-flex align-center justify-center">
                <v-icon color="success">mdi-check-circle</v-icon>
                <p v-if="id_causa === 1" class="mb-0 py-2 pl-2"> Su solicitud ya fue pagada</p>
                <p v-if="id_causa === 2" class="mb-0 py-2 pl-2"> Su solicitud ya fue ingresada correctamente</p>
              </div>

            </v-alert>


          </div>
        </v-fade-transition>
        <v-fade-transition>
          <div v-if="id_causa === 3 || id_causa === 4 || id_causa === 5">
            <v-alert
                text
                type="warning"
                :icon="false"
            >
              <div class="d-flex align-center justify-center">
                <v-icon color="warning">mdi-close-circle</v-icon>
                <p v-if="id_causa === 3" class="mb-0 py-2 pl-2"> Solo se puede solicitar la devolución para carátulas
                  con retiro valija electrónica</p>
                <p v-if="id_causa === 4" class="mb-0 py-2 pl-2"> La carátula ingresada no le pertenece, solo puede
                  solicitar la devolución de saldo a carátulas a su nombre</p>
                <p v-if="id_causa === 5" class="mb-0 py-2 pl-2"> La carátula ingresada no posee saldo a favor</p>
              </div>

            </v-alert>


          </div>
        </v-fade-transition>


        <div class="text-center">
          <v-btn outlined small color="primary" @click="irAMiCuenta()">
            <!--   <v-icon small class="pr-1">mdi-exit-to-app</v-icon> -->
            Ir a mi cuenta
          </v-btn>
        </div>

      </v-col>

    </v-row>
  </div>
</template>

<script>
export default {
  name: "DevolucionBloqueado",
  props: {
    causa: {default: null}
  },
  data() {
    return {
      id_causa: 0
    }
  },
  watch: {
    causa() {
      this.parseCausa();
    }
  },
  created() {
    console.log("Hola")
    console.log("Caratula: ", this.causa)
    this.parseCausa();
  },
  methods: {
    parseCausa() {
      console.log("Entra a parseCausa")
      switch (this.causa) {
        case "PAGADO":
          this.id_causa = 1;
          break;
        case "YA_GENERADA":
          this.id_causa = 2;
          break;
        case "NOT_TIPO_RETIRO":
          this.id_causa = 3;
          break;
        case "NOT_MATCH":
          this.id_causa = 4;
          break;
        case "NOT_SALDO_FAVOR":
          this.id_causa = 5;
          break;
      }
    },
    irAMiCuenta(){
      this.$router.push("/cliente/caratulas");
    }
  }
}
</script>

<style scoped>

</style>
