<template>
  <v-container class="d-flex justify-center align-center fill-height">
      <div class="card-minimal flex-grow-1">
        <FormularioRecuperarCuenta/>
      </div>
  </v-container>
</template>

<script>
import FormularioRecuperarCuenta from "@/components/RecuperarCuenta/FormularioRecuperarCuenta";

export default {
  name: "RecuperarPasswordView",
  components: {FormularioRecuperarCuenta}
}
</script>

<style scoped>

</style>