<template>
  <div>
    <v-fade-transition mode="out-in">

      <v-card v-if="cargando" key="cargando">
        <v-card-text>
          <v-skeleton-loader
              type="article,list-item-two-line, actions"
          />
        </v-card-text>
      </v-card>
      <v-card v-else key="info-cargada">
        <v-card-text class="d-flex flex-column align-center pb-0">
          <div class="mt-6">
            <v-avatar size="50" :color="data.activado ? 'primary' : 'error'">
              <v-icon size="40" color="white">mdi-account</v-icon>
            </v-avatar>
          </div>

          <div>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title text-center">{{ data.nombre_completo }}</v-list-item-title>
                <v-list-item-subtitle class="text-center">{{ data.rut_cliente_formateado }}</v-list-item-subtitle>
                <v-list-item-subtitle class="text-center">
                  <small v-if="data.activado">
                    <v-icon size="12" color="success">mdi-check-circle</v-icon>
                    Cliente activado para efectuar compras
                  </small>
                  <small v-else>
                    <v-icon size="12" color="error">mdi-alert-circle</v-icon>
                    Cliente no activado
                  </small>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>

          <div>
            <v-list dense flat id="info-usuario">

              <v-list-item>
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ data.correo }}</v-list-item-title>
                  <v-list-item-subtitle
                      v-if="!data.activado"
                      class="error--text"
                  >
                    <v-icon size="12" color="error">mdi-alert</v-icon>
                    su correo electrónico no ha sido verificado
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-home-map-marker</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ data.direccion }}</v-list-item-title>
                  <v-list-item-title>{{ data.region }}</v-list-item-title>
                  <v-list-item-title>{{ data.comuna }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-icon class="mr-3">
                  <v-icon>mdi-phone</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ data.telefono }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </v-list>
          </div>

        </v-card-text>
        <v-card-actions>
          <v-btn
              color="primary"
              block
              to="/cliente/editar"
          >
            <v-icon
                size="16"
                small
                left
            >
              mdi-account-edit
            </v-icon>
            Editar mi información
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-fade-transition>
  </div>
</template>

<script>
import usuarioEntity from "@/model/usuario/usuario.entity";


export default {
  name: "PerfilUsuario",
  data() {
    return {
      data: {},
      cargando: false,
      error: null
    }
  },
  mounted() {
    this.cargando = true;
    usuarioEntity.readUsuario()
        .then((response) => {
          this.data = response;
        })
        .catch((error) => {
          this.error = error.mensaje ? error.mensaje : error;
        })
        .finally(() => {
          this.cargando = false;
        });
  }
}
</script>

<style scoped>
#info-usuario .v-list-item__title{
  white-space: break-spaces;
}
</style>