<template>
  <v-card class="mb-6">
    <v-card-title>Cambio de contraseña</v-card-title>
    <v-card-text>
      <v-form @submit.prevent="cambiarPass()">
        <v-text-field
            v-model="passActual"
            label="Ingrese su contraseña actual"
            :append-icon="verPassActual ? 'mdi-eye' : 'mdi-eye-off'"
            :type="verPassActual ? 'text' : 'password'"
            @click:append="verPassActual = !verPassActual"
            :error-messages="errorPassActual"
            @blur="$v.passActual.$touch"
            @input="delayTouch($v.passActual)"
            :disabled="saving"
            v-disabled-icon-focus
        />
        <v-text-field
            v-model="passNueva"
            label="Ingrese la nueva contraseña"
            :append-icon="verPassNueva ? 'mdi-eye' : 'mdi-eye-off'"
            :type="verPassNueva ? 'text' : 'password'"
            @click:append="verPassNueva = !verPassNueva"
            :error-messages="errorPassNueva"
            @blur="$v.passNueva.$touch"
            @input="delayTouch($v.passNueva)"
            :disabled="saving"
            v-disabled-icon-focus
        />
        <v-text-field
            v-model="passNueva2"
            label="Confirme la nueva contraseña"
            :append-icon="verPassNueva2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="verPassNueva2 ? 'text' : 'password'"
            @click:append="verPassNueva2 = !verPassNueva2"
            :error-messages="errorPassNueva2"
            @blur="$v.passNueva2.$touch"
            @input="delayTouch($v.passNueva2)"
            :disabled="saving"
            v-disabled-icon-focus
        />
        <div>
          <v-fade-transition>
            <v-alert
                v-if="response"
                border="left"
                type="success"
                text
            >
              {{ response }}
            </v-alert>
          </v-fade-transition>
          <v-fade-transition>
            <v-alert
                v-if="error"
                border="left"
                type="error"
                text
            >
              {{ error }}
            </v-alert>
          </v-fade-transition>

        </div>
        <div class="d-flex justify-end">
          <v-btn small
                 color="primary"
                 type="submit"
                 :loading="saving"
                 ref="botonPass"
          >
            <v-icon size="16" left>mdi-content-save</v-icon>
            Cambiar contraseña
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {minLength, required, sameAs} from "vuelidate/lib/validators";
import usuarioEntity from "@/model/usuario/usuario.entity";
import DisabledIconFocusDirective from "@/directives/DisabledIconFocusDirective";

const touchMap = new WeakMap();

export default {
  name: "CambiarPasswordUsuario",
  directives: {DisabledIconFocusDirective},
  data() {
    return {
      verPassActual: false,
      verPassNueva: false,
      verPassNueva2: false,

      passActual: null,
      passNueva: null,
      passNueva2: null,

      saving: false,
      response: null,
      error: null,
    }
  },
  validations: {
    passActual: {required, minLength: minLength(6)},
    passNueva: {required, minLength: minLength(6)},
    passNueva2: {required, minLength: minLength(6), sameAsPassNueva: sameAs('passNueva')},
  },
  methods: {
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
    cambiarPass() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.saving = true;
      this.response = null;
      this.error = null;

      usuarioEntity.cambiarPassLogeado(this.passActual, this.passNueva2)
          .then((response) => {
            this.response = response;
            this.passActual = null;
            this.passNueva = null;
            this.passNueva2 = null;
            this.verPassActual = false;
            this.verPassNueva = false;
            this.verPassNueva2 = false;
            this.$v.$reset();
          })
          .catch((error) => {
            this.error = error.mensaje ? error.mensaje : error;
          })
          .finally(() => {
            this.saving = false;
          })
    }
  },
  computed: {
    errorPassActual() {
      const errors = [];
      if (!this.$v.passActual.$dirty) return errors;
      !this.$v.passActual.required && errors.push("Ingrese su contraseña actual");
      !this.$v.passActual.minLength && errors.push("La contraseña debe contener al menos 6 caracteres");
      return errors;
    },
    errorPassNueva() {
      const errors = [];
      if (!this.$v.passNueva.$dirty) return errors;
      !this.$v.passNueva.required && errors.push("Ingrese una contraseña nueva");
      !this.$v.passNueva.minLength && errors.push("La contraseña debe contener al menos 6 caracteres");
      return errors;
    },
    errorPassNueva2() {
      const errors = [];
      if (!this.$v.passNueva2.$dirty) return errors;
      !this.$v.passNueva2.required && errors.push("Confirme su contraseña nueva");
      !this.$v.passNueva2.sameAsPassNueva && errors.push("Las contraseñas deben ser idénticas");
      !this.$v.passNueva2.minLength && errors.push("La contraseña debe contener al menos 6 caracteres");
      return errors;
    },
  }
}
</script>

<style scoped>

</style>