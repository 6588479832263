<template>
  <div>
    <v-container>
      <div v-if="!isLogged">
        <v-row>
          <v-col cols="12">
            <v-alert
                text
                class="mt-6"
                border="left"
                type="info"
            >
              Para solicitar la devolución de un saldo de carátula primero debe <a href="#"
                                                                                   @click="modalIniciarSesion()">iniciar
              sesión</a>.
            </v-alert>
          </v-col>
        </v-row>
      </div>
      <div v-if="!verificando_caratula && isLogged">
        <div v-if="!puede_pagar">
          <devolucion-bloqueado :causa="causa"></devolucion-bloqueado>
        </div>
        <div v-if="puede_pagar">
          <devolucion-formulario :caratula="caratula" @solicitudGenerada="finalizarSolicitud()"></devolucion-formulario>
        </div>
      </div>
    </v-container>

    <simple-modal-static :ver-dialog="modalSolicitudGenerada" :tipo="'success'"
                         @ocultarDialog="modalSolicitudGenerada = false">

      <h3 class="text-center font-weight-medium d-flex align-center justify-center">
        <v-icon color="success" class="pr-1">mdi-check-circle-outline</v-icon>
        Solicitud generada
      </h3>

      <p class="text-center  my-2">Su solicitud de devolución ha sido ingresada correctamente</p>

      <div class="text-center pt-2">
        <v-btn outlined small color="success" @click="irAMiCuenta()">
          <!--   <v-icon small class="pr-1">mdi-exit-to-app</v-icon> -->
          Ir a mis carátulas
        </v-btn>
      </div>


    </simple-modal-static>
  </div>
</template>

<script>
import DevolucionBloqueado from "./DevolucionBloqueado";
import DevolucionFormulario from "./DevolucionFormulario";
import caratulaEntity from "../../../../model/caratula/caratula.entity";
import {EventBus} from "@/main";
import usuarioEntity from "../../../../model/usuario/usuario.entity";
import SimpleModalStatic from "../../../../components/SimpleModalStatic";

export default {
  name: "DevolucionView",
  components: {SimpleModalStatic, DevolucionFormulario, DevolucionBloqueado},
  data() {
    return {
      numero_caratula: null,
      puede_pagar: false,
      verificando_caratula: false,
      causa: null,
      isLogged: false,
      modalSolicitudGenerada: false
    }
  },
  watch: {
    getCaratulaFromUrl() {
      this.validarUrl();
    },
    isLogged() {
      if (this.isLogged) {
        this.validarUrl();
      }
    }
  },
  mounted() {
    this.verificando_caratula = true;
    EventBus.$on("login", (login) => {
      console.log("Login?: ", login)
      this.isLogged = login;
    });
    usuarioEntity.isSessionActive()
        .then((isActivo) => {
          this.isLogged = isActivo
          this.validarUrl();
        });


  },
  methods: {
    validarUrl() {
      console.log("entra a validar url")
      if (this.$route.params.numero_caratula != null) {
        caratulaEntity.getCaratulaDevolucion(this.$route.params.numero_caratula).then(resp => {
          this.caratula = resp;
          this.puede_pagar = true
          console.log("busca caratula devolucion")
          this.verificando_caratula = false;
        }).catch(er => {
          console.log("error: ", er)
          if (er.code === "NOT_LOGGED") {
            this.isLogged = false;
            this.modalIniciarSesion();
          }
          this.puede_pagar = false
          this.causa = er.obj;
          this.verificando_caratula = false;
        })

      } else {
        this.$router.push("/cliente/caratulas");
      }
    },
    modalIniciarSesion() {
      EventBus.$emit("modal-login", true);
    },

    finalizarSolicitud() {
      this.puede_pagar = false;
      this.causa = "YA_GENERADA"
      this.modalSolicitudGenerada = true;
    },
    irAMiCuenta(){
      this.$router.push("/cliente/caratulas");
    }
  },


  computed: {
    getCaratulaFromUrl() {
      return this.$route.params.numero_caratula
    }
  }
}
</script>

<style scoped>

</style>
