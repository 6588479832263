<template>
  <v-dialog max-width="570" v-model="ver" persistent>
    <v-card>
      <v-card-text>
        <v-row v-if="!generandoSolicitudes">
          <v-col cols="12">
            <modal-pago-medios-pago :precio_total_carro="total_carro" :ayuda="true" @updateTipoPago="updateTipoPago($event)"></modal-pago-medios-pago>
            <v-divider></v-divider>
          </v-col>


          <v-col cols="6" class="pl-7 pt-0">
            <!--
            <p class="font-sm mb-0">Subtotal: {{ subtotal | clp }}</p>
            <p class="font-sm mb-0" v-if="hasChilexpress">Envío: $3.500</p>
            <p class="font-sm mb-0">Cargos operacionales khipu: {{ comision | clp }}</p>
            -->
          </v-col>
          <v-col cols="6" class="d-flex justify-end pr-7 pt-0">
            <p class="font-sm mb-0 mt-auto font-weight-bold" style="font-size: 16px">Total: {{
                total_carro | clp
              }}</p>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="grey" outlined class="mr-3" @click="hideDialog()" :disabled="generandoSolicitudes" small>
              <v-icon small>mdi-close</v-icon>
              Volver
            </v-btn>
            <v-btn color="success" outlined class="px-10" :disabled="!valido" @click="enviarFormulario()"
                   :loading="generandoSolicitudes" small>
              <v-icon class="pr-1" small>mdi-credit-card-multiple-outline</v-icon>
              Pagar
            </v-btn>
            <p class="text-center font-md error--text mb-0" v-if="errorFormulario != null">{{ errorFormulario }}</p>
          </v-col>
        </v-row>
        <v-fade-transition>
          <v-row v-show="generandoSolicitudes">

            <v-col cols="12" class="px-15">
              <div class="text-center pt-2">
                <img src="@/assets/img/khipu.png" width="200"/></div>
              <p class="text-center pb-3">Conectando con KHIPU, espere un momento...</p>
              <v-progress-linear
                  color="purple lighten-2"
                  buffer-value="0"
                  reverse
                  stream
              ></v-progress-linear>
            </v-col>

          </v-row>
        </v-fade-transition>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalPagoMediosPago from "@/components/Carro/ModalPagoMediosPago";
import usuarioEntity from "@/model/usuario/usuario.entity";
import carroEntity, {ID_TIPO_PAGO, ID_TIPO_RETIRO} from "@/model/carro/carro.entity";

export default {
  name: "ModalContinuarCompra",
  components: {ModalPagoMediosPago},
  props: {
    verDialog: {default: false},
    time: {default: null},
    subtotal: {default: 0},
    solicitudes: {default: null},
    id_carro_solicitud_cliente: {default: 0},
    total_carro: {defaul: 0}
  },
  data() {
    return {
      ver: false,
      comunas: [],
      isLoading: false,
      formularioValido: false,
      dataFormulario: {},
      valido: false,
      errorFormulario: null,
      precio_total_carro: 0,
      comision: 0,
      tipo_retiro: null,
      ID_TIPO_RETIRO: ID_TIPO_RETIRO,
      generandoSolicitudes: false,
      hasChilexpress: false
    }
  },

  created() {

    this.isLoading = true
    usuarioEntity.getAllComunasDeRegiones(this.search).then(response => {
      this.comunas = response;
    }).catch(error => {
      console.log("Error: ", error)
    }).finally(() => (this.isLoading = false))
  },
  computed: {},
  watch: {
    verDialog() {
      this.ver = this.verDialog;
    },

    solicitudes() {
      this.checkPlanos()
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    },
    tipo_retiro() {
      this.getPrecioTotal();
    },
    subtotal() {
      this.getPrecioTotal();
    }
  },

  methods: {
    checkPlanos() {
      this.hasChilexpress = false;
      this.solicitudes.map(sol => {
        if (sol.id_tipo_retiro_FK == ID_TIPO_RETIRO.CHILEXPRESS) {
          this.hasChilexpress = true;
        }
      })
    },
    setTipoRetiro(tipo_retiro) {
      this.tipo_retiro = tipo_retiro;
    },

    isFormularioValido() {
      if (this.dataFormulario == null) {
        return false;
      }
      return this.dataFormulario.id_tipo_pago != null;
    },

    getPrecioTotal() {
      let tot = this.subtotal;
      if (this.hasChilexpress) {
        tot = this.subtotal + 3500;
      }
      if (this.dataFormulario.id_tipo_pago === ID_TIPO_PAGO.KHIPU) {
        tot = parseInt(tot * carroEntity.COMISION.KHIPU)
      }
      if (this.dataFormulario.id_tipo_pago === ID_TIPO_PAGO.KHIPU_WEB) {
        tot = parseInt(tot * carroEntity.COMISION.KHIPU_WEB)
      }

      this.precio_total_carro = tot
      this.getComision();
    },
    getComision() {

      let tot = this.subtotal;
      if (this.hasChilexpress) {
        tot = this.subtotal + 3500;
      }
      if (this.dataFormulario.id_tipo_pago === ID_TIPO_PAGO.KHIPU) {
        this.comision = parseInt(tot * (carroEntity.COMISION.KHIPU - 1))
      }
      if (this.dataFormulario.id_tipo_pago === ID_TIPO_PAGO.KHIPU_WEB) {
        this.comision = parseInt(tot * (carroEntity.COMISION.KHIPU_WEB - 1))
      }

    },
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    updateTipoPago(id_tipo_pago) {
      this.dataFormulario.id_tipo_pago = id_tipo_pago;
      this.valido = this.isFormularioValido();
      this.getPrecioTotal();
    },

    enviarFormulario() {
      this.generandoSolicitudes = true;
      this.$emit("generandoCarro", null);
      carroEntity.continuarPagoCarro(this.id_carro_solicitud_cliente, this.dataFormulario.id_tipo_pago).then(resp => {

        window.location = resp;

      }).catch(error => {
        this.errorFormulario = error.mensaje;
      }).finally(() => {
        /*this.generandoSolicitudes = false;*/
      });
    }
  }
}
</script>

<style scoped>

</style>
