<template>
  <div v-if="caratula != null">
    <div v-if="caratula.estado_saldo === 0 && caratula.saldo !== 0">
      <div v-if="caratula.saldo > 0">
        <div
            v-if="caratula.id_tipo_retiro === ID_TIPO_RETIRO.VALIJA_ELECTRONICA || (caratula.id_tipo_retiro !== ID_TIPO_RETIRO.VALIJA_ELECTRONICA && caratula.rut_usuario_cbrls === RUT_USUARIO.WEB)">
          <div
              v-if="caratula.id_estado_caratula_FK === ID_ESTADO_CARATULA.LISTA_PARA_FIRMAR || (caratula.id_tipo_retiro !== ID_TIPO_RETIRO.VALIJA_ELECTRONICA && caratula.rut_usuario_cbrls === RUT_USUARIO.WEB)">
            <!-- Mensaje 2-->
            <v-alert
                color="info"
                dense
                text
                :icon="false"
                class="text-center font-md"
            >
              Esta carátula tiene un saldo de
              {{ caratula.saldo| clp }}
              <v-btn color="warning" block class="my-2" x-small
                     @click="pagarSaldoAdicional(caratula)"><i class="fa fa-credit-card-alt mr-1"></i>Ir
                a Pagar
              </v-btn>
            </v-alert>
          </div>
          <div
              v-if="caratula.id_estado_caratula_FK !== ID_ESTADO_CARATULA.LISTA_PARA_FIRMAR && caratula.id_tipo_retiro === ID_TIPO_RETIRO.VALIJA_ELECTRONICA">
            <!-- Mensaje 3-->
            <v-alert
                color="warning"
                dense
                text
                :icon="false"
                class="text-center font-md"
            >
              Existe un saldo de carátula. Podrá pagarlo una
              vez que toda su documentación esté lista para la firma electrónica.
            </v-alert>

          </div>
        </div>

        <div
            v-if="caratula.id_tipo_retiro !== ID_TIPO_RETIRO.VALIJA_ELECTRONICA && caratula.rut_usuario_cbrls !== RUT_USUARIO.WEB">
          <!-- Mensaje 1-->

          <v-alert
              color="red"
              dense
              text
              :icon="false"
              class="text-center font-md"
          >
            Estimado(a) cliente(a), hay un saldo pendiente de {{ caratula.saldo| clp }}. Puede realizar
            el pago en el Conservador de Bienes Raíces de Coquimbo o coordinarlo al correo secretaria@conservadorcoquimbo.cl
          </v-alert>

        </div>
      </div>

      <div v-if="caratula.saldo < 0">
        <div v-if="caratula.id_tipo_retiro === ID_TIPO_RETIRO.VALIJA_ELECTRONICA">
          <!-- Mensaje 5-->
          <v-alert
              color="info"
              dense
              text
              :icon="false"
              class="font-md text-center"
          >
            Esta carátula tiene un saldo a favor de
            {{ caratula.saldo * (-1) | clp }}
            <v-btn class="my-2" color="primary" block x-small
                   @click="solicitarDevolucion(caratula.numero_caratula)"><i class="fa fa-credit-card-alt mr-1"></i>Solicitar
              devolución
            </v-btn>
          </v-alert>

        </div>
        <div v-if="caratula.id_tipo_retiro != ID_TIPO_RETIRO.VALIJA_ELECTRONICA">
          <!-- Mensaje 4-->
          <v-alert
              color="info"
              dense
              text
              :icon="false"
              class="font-md text-center"
          >
            Estimado(a) cliente(a), posee un saldo a favor de {{ caratula.saldo * (-1) | clp }}
            que debe ser retirado en las dependencias del Conservador de Bienes Raíces de Coquimbo.
          </v-alert>

        </div>
      </div>
    </div>

    <div v-if="caratula.estado_saldo === 1 && caratula.saldo !== 0">

      <v-alert
          color="success"
          dense
          text
          :icon="false"
          class="text-center font-md"
      >
        El saldo de esta carátula (<span
          v-if='caratula.saldo > 0'>{{ caratula.saldo| clp }}) fue pagado.</span><span
          v-if='caratula.saldo < 0'>{{ caratula.saldo * (-1) | clp }}) fue devuelto.</span>
      </v-alert>

    </div>
    <modal-pago-saldo :ver-dialog="ver_modal_pago_saldo"
                      :caratula="caratula_seleccionada"
                      @ocultarDialog="ver_modal_pago_saldo = false"></modal-pago-saldo>
  </div>
</template>

<script>
import {ID_TIPO_RETIRO} from "@/model/carro/carro.entity";
import {RUT_USUARIO} from "@/model/usuario/usuario.entity";
import {ID_ESTADO_CARATULA} from "@/model/caratula/caratula.entity";
import ModalPagoSaldo from "./ModalPagoSaldo";

export default {
  name: "MisCaraulasDetalleSaldo",
  components: {ModalPagoSaldo},
  props: {
    caratula: {default: null}
  },
  data() {
    return {
      ID_TIPO_RETIRO: ID_TIPO_RETIRO,
      RUT_USUARIO: RUT_USUARIO,
      ID_ESTADO_CARATULA: ID_ESTADO_CARATULA,
      ver_modal_pago_saldo: false,
      caratula_seleccionada: null
    }
  },
  methods: {
    pagarSaldoAdicional(caratula) {
      this.caratula_seleccionada = caratula;
      this.ver_modal_pago_saldo = true;
    },
    solicitarDevolucion(numero_caratula) {
      this.$router.push("/devoluciones/" + numero_caratula)
    }
  }
}
</script>

<style scoped>

</style>
