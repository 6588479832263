<template>
  <div class="mt-6">
    <v-row>
      <v-col
          cols="12"
          md="4"
      >
        <PerfilUsuario/>
      </v-col>

      <v-col
          cols="12"
          md="8"
      >
        <CardOpcionMenu
            class="mb-6"
            to="/cliente/compras"
            titulo="Mis Compras"
            icono="mdi-cart"
            to-left
            :max-height="false"
        >
          Visualice sus carros de compras y retome el pago en caso de no haberlo concretado..
        </CardOpcionMenu>

        <CardOpcionMenu
            class="mb-6"
            to="/cliente/caratulas"
            titulo="Mis Carátulas"
            icono="mdi-file"
            to-left
            :max-height="false"
        >
          Visualice sus carátulas Web y/o Presenciales, consulte estados, pague saldos y/o solicite devoluciones cuando
          corresponda.
        </CardOpcionMenu>

        <CardOpcionMenu
            class="mb-6"

            :titulo="`Mis Carátulas por Corregir ${caratulasCorreccionDocs.length > 0 ? '(' + caratulasCorreccionDocs.length + ')': ''}`"
            icono="mdi-file-edit"
            to="/cliente/caratulas-por-corregir"
            to-left
            :max-height="false"
            :disabled="caratulasCorreccionDocs.length == 0 "
        >
          Corrija sus documentos que fueron marcados con datos que no corresponden o solicite la devolución de estos.
        </CardOpcionMenu>

      </v-col>

    </v-row>

  </div>
</template>

<script>


import PerfilUsuario from "@/components/DatosUsuario/PerfilUsuario";
import CardOpcionMenu from "@/components/CardOpcionMenu/CardOpcionMenu";
import caratulaEntity from "@/model/caratula/caratula.entity";

export default {
  name: "ClienteViewMenu",
  components: {PerfilUsuario, CardOpcionMenu},
  data() {
    return {
      load: {
        caratulasConDocsCorreccion: false
      },
      caratulasCorreccionDocs: []
    }
  },
  created() {
    this.load.caratulasConDocsCorreccion = true;
    caratulaEntity.getCaratulasConDocumentosPorCorregir(true).then(res => {
      this.load.caratulasConDocsCorreccion = false;
      this.caratulasCorreccionDocs = res;
      console.log("caratulas encontradas: ", res)
    })
  }

}
</script>

<style scoped>

</style>
