<template>
  <v-dialog max-width="570" v-model="ver" persistent>
    <v-card v-if="caratula != null">
      <v-card-text>
        <v-row v-if="!redireccionando">
          <v-col cols="12">
            <modal-pago-medios-pago :ayuda="true" :precio_total_carro="caratula.saldo" @updateTipoPago="updateTipoPago($event)"></modal-pago-medios-pago>
            <v-divider></v-divider>
          </v-col>


          <v-col cols="6" class="pl-7 pt-0">
            <p class="mb-0">Subtotal: {{ caratula.saldo | clp }}</p>
            <p class="mb-0">Comisión khipu: {{ comision | clp }}</p>
          </v-col>
          <v-col cols="6" class="d-flex justify-end pr-7 pt-0">
            <p class="font-sm mb-0 mt-auto font-weight-bold" style="font-size: 16px">Total: {{
                precio_total | clp
              }}</p>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="grey" outlined class="mr-3" @click="hideDialog()" :disabled="redireccionando" small>
              <v-icon small>mdi-close</v-icon>
              Volver
            </v-btn>
            <v-btn color="success" outlined class="px-10" :disabled="!valido" @click="enviarFormulario()"
                   :loading="redireccionando" small>
              <v-icon class="pr-1" small>mdi-credit-card-multiple-outline</v-icon>
              Pagar
            </v-btn>
          </v-col>
        </v-row>
        <v-fade-transition>
          <v-row v-show="redireccionando">

            <v-col cols="12" class="px-15">
              <div class="text-center pt-2">
                <img src="@/assets/img/khipu.png" width="200"/></div>
              <p class="text-center pb-3">Conectando con KHIPU, espere un momento...</p>
              <v-progress-linear
                  color="purple lighten-2"
                  buffer-value="0"
                  reverse
                  stream
              ></v-progress-linear>
            </v-col>

          </v-row>
        </v-fade-transition>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalPagoMediosPago from "@/components/Carro/ModalPagoMediosPago";
import caratulaEntity from "../../../../model/caratula/caratula.entity";
import carroEntity, {ID_TIPO_PAGO} from "../../../../model/carro/carro.entity";

export default {
  name: "ModalPagoSaldo",
  components: {ModalPagoMediosPago},
  props: {
    verDialog: {default: false},
    time: {default: null},
    caratula: {default: null}

  },
  data() {
    return {
      ver: false,
      isLoading: false,
      formularioValido: false,
      dataFormulario: {},
      redireccionando: false,
      valido: false,
      precio_total: 0,
      comision: 0
    }
  },
  watch: {
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    },
    caratula() {
      this.getPrecioTotal();
    }

  },
  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    updateTipoPago(id_tipo_pago) {
      this.dataFormulario.id_tipo_pago = id_tipo_pago;
      this.valido = this.isFormularioValido();
      this.getPrecioTotal();
    },
    enviarFormulario() {
      this.redireccionando = true;
      caratulaEntity.getLinkPagoSaldo(this.caratula.numero_caratula, this.dataFormulario.id_tipo_pago).then(resp => {
        location.href = resp
      })

    },
    isFormularioValido() {
      if (this.dataFormulario == null) {
        return false;
      }
      return this.dataFormulario.id_tipo_pago != null;
    },
    getPrecioTotal() {
      if (this.caratula != null) {
        let tot = parseInt(this.caratula.saldo);

        if (this.dataFormulario.id_tipo_pago === ID_TIPO_PAGO.KHIPU) {
          tot = parseInt(tot * carroEntity.COMISION.KHIPU)
        }
        if (this.dataFormulario.id_tipo_pago === ID_TIPO_PAGO.KHIPU_WEB) {
          tot = parseInt(tot * carroEntity.COMISION.KHIPU_WEB)
        }

        this.precio_total = tot;
        this.comision = this.precio_total - this.caratula.saldo;
      }

    },
  }
}
</script>

<style scoped>

</style>
