<template>
  <v-card>
    <v-card-title>
      Recuperar Cuenta
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-card outlined>
            <v-card-text>
              Es necesario que ingrese su RUT y correo electrónico que asoció a su cuenta.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-form @submit.prevent="handleRecuperarCuenta()">
        <v-text-field
            v-model="rut"
            label="Rut"
            placeholder="Ej: 16.652.652-3"
            required
            :error-messages="errorsRut"
            @input="delayTouch($v.rut)"
            @blur="$v.rut.$touch()"
            :change="formatRut()"
            :disabled="enviandoPeticion"
            v-disabled-icon-focus
            :autofocus="!$vuetify.breakpoint.mobile"
        />
        <v-text-field
            class="text-sm"
            type="email"
            v-model="correo"
            label="Correo electrónico"
            placeholder="Ej: starlight7@gmail.com"
            :error-messages="errorCorreo"
            @input="delayTouch($v.correo)"
            @blur="$v.correo.$touch"
            :disabled="enviandoPeticion"
        ></v-text-field>

        <v-fade-transition>
          <v-alert
              v-if="success"
              border="left"
              type="success"
              text
          >
            {{ success }}
          </v-alert>
        </v-fade-transition>
        <v-fade-transition>
          <v-alert
              v-if="error"
              border="left"
              type="error"
              text
          >
            {{ error }}
          </v-alert>
        </v-fade-transition>

        <div class="d-flex justify-end">
          <v-btn
              small
              color="primary"
              :loading="enviandoPeticion"
              type="submit"
          >
            <v-icon left size="16">
              mdi-send
            </v-icon>
            Enviar Solicitud
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import {rutClean, rutFormat, rutValidate} from "rut-helpers";
import {required, email} from "vuelidate/lib/validators";
import usuarioEntity from "@/model/usuario/usuario.entity";

const rutValidator = (rut) => rutValidate(rut);
const touchMap = new WeakMap();

export default {
  name: "FormularioRecuperarCuenta",
  data() {
    return {
      rut: null,
      correo: null,
      //
      enviandoPeticion: false,
      error: null,
      success: null,
    }
  },
  validations: {
    rut: {required, rutValidator},
    correo: {required, email},
  },
  methods: {
    clearRut(rutString) {
      return rutClean(rutString).slice(0, -1);
    },
    formatRut() {
      this.rut = rutFormat(this.rut);
    },
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
    async handleRecuperarCuenta() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.enviandoPeticion = true;
      this.success = false;
      this.error = false;
    
      await this.$recaptchaLoaded();
      // Execute reCAPTCHA with action "login".
      var t = await this.$recaptcha('login');
      
      usuarioEntity.recuperarCuenta(this.clearRut(this.rut),this.correo, t)
          .then((response) => {
            this.success = response;
          })
          .catch((error) => {
            this.error = error.mensaje ? error.mensaje : error;
          })
          .finally(() => {
            this.enviandoPeticion = false;
          });

      console.log("submit")
    },
  },
  computed: {
    errorsRut: function () {
      const errors = [];
      if (!this.$v.rut.$dirty) return errors;
      !this.$v.rut.required && errors.push("Ingrese su rut.");
      !this.$v.rut.rutValidator && errors.push("Ingrese un rut válido.");
      return errors;
    },
    errorCorreo() {
      const errors = [];
      if (!this.$v.correo.$dirty) return errors;
      !this.$v.correo.required && errors.push("Ingrese su correo electrónico");
      !this.$v.correo.email && errors.push("Ingrese un correo electrónico válido");
      return errors;
    },
  },
}
</script>

<style scoped>

</style>