<template>
  <v-container class="d-flex justify-center align-center fill-height">
    <div class="card-minimal flex-grow-1">
      <v-fade-transition mode="out-in">
        <div key="verificando" v-if="verificando"
             class="d-flex justify-center"
        >
          <v-progress-circular
              indeterminate
              color="primary"
          />
        </div>
        <v-alert
            key="error"
            v-if="error"
            border="left"
            type="error"
            text
        >
          {{ error }}
        </v-alert>
        <FormularioCambiarPasswordRecuperacion
            key="formulario"
            v-if="success"
            :fecha-registro="fechaRegistro"
            :rut-cliente="rutCliente"
        />
      </v-fade-transition>
    </div>
  </v-container>
</template>

<script>
import usuarioEntity from "@/model/usuario/usuario.entity";
import FormularioCambiarPasswordRecuperacion from "@/components/RecuperarCuenta/FormularioCambiarPasswordRecuperacion";

export default {
  name: "CambioPasswordRecuperarView",
  components: {FormularioCambiarPasswordRecuperacion},
  data() {
    return {
      success: null,
      error: null,
      verificando: true,
      //
      fechaRegistro: null,
      rutCliente: null,
    }
  },
  mounted() {
    if (!this.$route.query.r || !this.$route.query.p) {
      this.error = "Error al obtener la información del usuario que desea cambiar su contraseña (código 1)";
      console.error("p: ", this.$route.query.p, "r:", this.$route.query.r);
      this.verificando = false;
      return;
    }

    usuarioEntity.verificarUpdatePasswordRecuperarcuenta(this.$route.query.r, this.$route.query.p)
        .then((response) => {
          if (!response.fechaRegistro || !response.rutCliente) {
            this.error = "Error al obtener la información del usuario que desea cambiar su contraseña (código 2)";
            console.error(response);
            return;
          }
          this.fechaRegistro = response.fechaRegistro;
          this.rutCliente = response.rutCliente;
          this.success = true;
        })
        .catch((error) => {
          this.error = error.mensaje ? error.mensaje : error;
        })
        .finally(() => {
          this.verificando = false;
        });
  }
}
</script>

<style scoped>

</style>