<template>
  <div>
    <v-row v-if="caratula != null">
      <v-col cols="5" class="">
        <v-card>
          <v-card-text>
            <h3 class="text-center">Carátula N° {{ caratula.numero_caratula }}</h3>
            <v-divider class="my-3"></v-divider>
            <p class="font-weight-medium text-center mb-1">Requirente</p>
            <p class="text-center">{{ caratula.solicitud_cliente.nombre_completo }}</p>
            <v-divider class="my-3"></v-divider>
            <p class="font-weight-medium text-center mb-1">Fecha de generación de la carátula</p>
            <p class="text-center">{{ caratula.fecha_generacion_caratula | toDate }}</p>
            <v-divider class="my-3"></v-divider>
            <p class="font-weight-medium text-center mb-1">Estado de la carátula</p>
            <p class="text-center px-md-10">{{ caratula.ultimo_estado_caratula }}</p>
            <v-divider class="my-3"></v-divider>
            <p class="font-weight-medium text-center mb-1">Forma de retiro</p>
            <p class="text-center">{{ caratula.solicitud_cliente.tipo_retiro }}</p>
            <v-divider class="my-3"></v-divider>
            <p class="font-weight-medium text-center mb-1">Correo para el envío del documento</p>
            <p class="text-center">{{ caratula.solicitud_cliente.direccion_envio }}</p>
            <v-divider class="my-3"></v-divider>
            <p class="font-weight-medium text-center mb-1">Saldo a favor</p>
            <p class="text-center">{{ caratula.saldo * -1 | clp }}</p>


          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="7" class="">
        <v-card class="px-7">
          <v-card-text>
            <h3 class="text-center">Datos de la cuenta bancaria</h3>
            <v-divider class="my-3"></v-divider>
            <v-row>
              <v-col cols="7" class="pb-0">
                <v-text-field
                    outlined
                    dense
                    prepend-icon="mdi-account"
                    label="Nombre completo"
                    v-model="nombre_cuenta"
                    type="text"
                    :error-messages="errors.errorNombreCompleto($v.nombre_cuenta)"
                    @input="delayTouch($v.nombre_cuenta)"
                    @blur="$v.nombre_cuenta.$touch()"
                    placeholder="Ej: James Saúl Morgan McGill"
                    :autofocus="!$vuetify.breakpoint.mobile"
                ></v-text-field>
              </v-col>
              <v-col cols="5" class="pb-0">
                <v-text-field
                    outlined
                    label="Rut"
                    placeholder="Ej: 11.111.111-1"
                    dense
                    v-model="rut_cuenta"
                    :change="formatRut()"
                    :error-messages="errors.errorsRut($v.rut_cuenta)"
                    @input="delayTouch($v.rut_cuenta)"
                    @blur="$v.rut_cuenta.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-select
                    dense
                    :items="bancos"
                    label="Banco"
                    outlined
                    prepend-icon="mdi-bank"
                    v-model="banco"
                    @blur="$v.banco.$touch()"
                    :error-messages="errors.errorBanco($v.banco)"
                ></v-select>
              </v-col>
              <v-col cols="6" class="pb-0">
                <v-select
                    dense
                    :items="tipos_cuentas"
                    label="Tipo de cuenta"
                    outlined
                    prepend-icon="mdi-account-box-outline"
                    v-model="tipo_cuenta"
                    @blur="$v.tipo_cuenta.$touch()"
                    :error-messages="errors.errorTipoCuenta($v.tipo_cuenta)"
                ></v-select>
              </v-col>
              <v-col cols="6" class="pb-0">
                <v-text-field
                    outlined
                    label="N° de cuenta"
                    placeholder="Ej: 12345678"
                    dense
                    v-model="numero_cuenta"
                    :error-messages="errors.errorNumeroCuenta($v.numero_cuenta)"
                    @input="delayTouch($v.numero_cuenta)"
                    @blur="$v.numero_cuenta.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-text-field
                    outlined
                    label="Correo"
                    placeholder="micorreo@gmail.com"
                    dense
                    prepend-icon="mdi-email"
                    v-model="correo"
                    :error-messages="errors.errorCorreo($v.correo)"
                    @input="delayTouch($v.correo)"
                    @blur="$v.correo.$touch()"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pb-0">
                <v-textarea
                    outlined
                    name="input-7-4"
                    label="Observaciones"
                    rows="2"
                    prepend-icon="mdi-text"
                    v-model="observacion"
                ></v-textarea>
              </v-col>
              <v-col cols="12" class="text-center pt-1">
                <v-btn outlined color="primary" rounded @click="crearSolicitudDevolucion()">
                  <v-icon class="pr-1">mdi-email-send-outline</v-icon>
                  Enviar solicitud
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

  </div>
</template>

<script>


import caratulaEntity from "@/model/caratula/caratula.entity";
import {rutClean, rutFormat, rutValidate} from "rut-helpers";
import {bancos, cuentas} from "../../../../model/formularios/formulario.entity";
import {required} from "vuelidate/lib/validators";
import validEntity from "../../../../model/validaciones/valid.entity";
import {nombreApellidoValidate} from "@/model/validaciones/valid.entity";
const touchMap = new WeakMap();

export default {
  name: "DevolucionFormulario",
  components: {},
  props: {
    caratula: {default: null}
  },
  data() {
    return {
      bancos: bancos,
      tipos_cuentas: cuentas,
      numero_caratula: null,
      nombre_cuenta: "",
      rut_cuenta: null,
      banco: null,
      tipo_cuenta: null,
      numero_cuenta: null,
      correo: "",
      observacion: "",
      dataSolicitud: {},
      errors: validEntity
    }
  },
  validations: {
    rut_cuenta: {required, rutValidate},
    nombre_cuenta: {required, nombreApellidoValidate},
    banco: {required},
    tipo_cuenta: {required},
    numero_cuenta: {required},
    correo: {required}
  },
  created() {

  },
  computed: {},
  methods: {
    crearSolicitudDevolucion() {

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }
      this.dataSolicitud.numero_caratula = this.caratula.numero_caratula
      this.dataSolicitud.numero_caratula = this.caratula.numero_caratula
      this.dataSolicitud.rut_cuenta = rutClean(this.rut_cuenta).slice(0, -1)
      this.dataSolicitud.nombre_cuenta = this.nombre_cuenta
      this.dataSolicitud.banco = this.banco
      this.dataSolicitud.tipo_cuenta = this.tipo_cuenta
      this.dataSolicitud.numero_cuenta = this.numero_cuenta
      this.dataSolicitud.correo = this.correo
      this.dataSolicitud.observacion = this.observacion

      caratulaEntity.createSolicitudDevolucion(this.dataSolicitud).then(() => {
        this.$emit("solicitudGenerada");
      }).catch(er => {
        console.log("Error: ", er)
      })
    },
    formatRut() {
      this.rut_cuenta = rutFormat(this.rut_cuenta);
    },
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },
  }
}
</script>

<style scoped>

</style>
