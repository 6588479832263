<template>
  <v-card>
    <v-card-title>Datos personales</v-card-title>
    <v-fade-transition mode="out-in">
      <v-card-text v-if="loading" key="cargando">
        <v-skeleton-loader
            type="article,list-item-two-line, article"
        />
      </v-card-text>
      <v-card-text v-else key="formulario-datos-personales">
        <v-form autocomplete="off" @submit.prevent="guardarDatos()">
          <v-text-field
              class="mb-2"
              v-model="nombreCompleto"
              label="Nombre completo"
              type="text"
              :error-messages="errorNombre"
              @input="delayTouch($v.nombreCompleto)"
              @blur="$v.nombreCompleto.$touch()"
              :disabled="saving"
          />
          <v-text-field
              class="mb-2"
              v-model="correo"
              label="Correo electrónico"
              placeholder="Ej: jimmy_mcgill@gmail.com"
              :error-messages="errorCorreo"
              @blur="$v.correo.$touch"
              @input="delayTouch($v.correo)"
              :disabled="saving"
          />
          <v-autocomplete class="mb-2"
                          v-model="comuna"
                          :items="itemsComunas"
                          :loading="cargandoComuna"
                          :search-input.sync="searchComuna"
                          hide-no-data
                          hide-selected
                          item-text="description"
                          item-value="API"
                          label="Comuna"
                          placeholder="Ej: Coquimbo, Coquimbo"
                          return-object
                          :error-messages="errorComuna"
                          @blur="$v.comuna.$touch()"
                          :disabled="saving"
          />
          <!-- direccion -->
          <vuetify-google-autocomplete
              class="mb-2"
              ref="direccionesGoogle"
              label="Domicilio"
              v-model="direccion"
              :error-messages="errorDireccion"
              @input="delayTouch($v.direccion)"
              @blur="$v.direccion.$touch"
              id="map"
              placeholder="Escriba la dirección de su domicilio."
              country="CL"
              @placechanged="mostrarEnMapa"
              :disabled="saving"
          >
          </vuetify-google-autocomplete>
          <v-slide-y-transition>
            <GmapMap
                class="mb-2"
                v-if="firstPlaceChanged"
                :center="centerMap"
                :zoom="14"
                :options="{
                      streetViewControl: false,
                    }"
                map-type-id="terrain"
                style="height: 300px"
            >
              <GmapMarker
                  v-if="markerMap.lat != null"
                  :position="markerMap"
              />
            </GmapMap>
          </v-slide-y-transition>

          <v-text-field
              class="mb-2"
              v-model="complementoDomicilio"
              type="text"
              label="Complementar domicilio"
              placeholder="Departamento, referencia de calle, etc."
              :disabled="saving"
          ></v-text-field>
          <v-text-field
              class="mb-2"
              counter
              v-model="telefono"
              label="Teléfono"
              :error-messages="errorTelefono"
              @blur="$v.telefono.$touch"
              @input="delayTouch($v.telefono)"
              placeholder="Ej: 912389076"
              :disabled="saving"
          />
          <div>
            <v-fade-transition>
              <v-alert
                  v-if="response"
                  border="left"
                  type="success"
                  text
              >
                {{ response }}
              </v-alert>
            </v-fade-transition>
            <v-fade-transition>
              <v-alert
                  v-if="error"
                  border="left"
                  type="error"
                  text
              >
                {{ error }}
              </v-alert>
            </v-fade-transition>

          </div>
          <div class="d-flex justify-end">
            <v-btn
                small
                color="primary"
                type="submit"
                :loading="saving"
            >
              <v-icon size="16" left>mdi-content-save</v-icon>
              Guardar cambios
            </v-btn>
          </div>
        </v-form>
      </v-card-text>
    </v-fade-transition>
  </v-card>
</template>

<script>
import usuarioEntity from "@/model/usuario/usuario.entity";
import {maxLength, minLength, required} from "vuelidate/lib/validators";

const touchMap = new WeakMap();

export default {
  name: "CambiarDatosPersonalesUsuario",
  data() {
    return {
      loading: true,
      saving: false,
      error: null,
      response: null,

      nombreCompleto: null,
      correo: null,
      telefono: null,
      direccion: null,
      complementoDomicilio: null,

      comuna: null,
      cargandoComuna: false,
      searchComuna: null,
      listaComunas: [],

      firstPlaceChanged: false,
      markerMap: {
        lat: null,
        lng: null
      },
      centerMap: {
        lat: -29.90453,
        lng: -71.24894
      },
    }
  },
  validations: {
    nombreCompleto: {required},
    direccion: {required},
    telefono: {required, minLength: minLength(9), maxLength: maxLength(9)},
    correo: {required},
    comuna: {required},
  },
  mounted() {
    this.loading = true;

    usuarioEntity.getAllComunasDeRegiones()
        .then((response) => {
          this.listaComunas = response;

          usuarioEntity.readUsuario()
              .then((response) => {
                this.nombreCompleto = response.nombre_completo;
                this.correo = response.correo;
                this.telefono = response.telefono;

                const comuna = this.listaComunas.find(comuna => {
                  return comuna.id_comuna === response.id_comuna_FK
                });
                comuna.description = `${comuna.comuna}, ${comuna.region}`;
                this.comuna = comuna;
                //
                const d = response.direccion;

                if(d.indexOf("[") == -1)
                {
                  //si no se encuentra un complemento de domicilio
                  this.direccion = d;
                }
                else
                {
                  this.direccion = d.substring(0, d.indexOf("["));
                  this.complementoDomicilio = d.substring(d.indexOf("[") + 2).replace("]", "").trim();
                }



              })
              .catch((error) => {
                this.error = error.mensaje ? error.mensaje : error;
              })

        })
        .catch((error) => {
          this.error = error.mensaje ? error.mensaje : error;
        })
        .finally(() => {
          this.loading = false;
        });


  },
  computed: {
    itemsComunas() {
      return this.listaComunas.map(comuna => {
        const description = `${comuna.comuna}, ${comuna.region}`
        return Object.assign({}, comuna, {description})
      })
    },
    errorComuna() {
      const errors = [];
      if (!this.$v.comuna.$dirty) return errors;
      !this.$v.comuna.required && errors.push("Ingrese la comuna en la cual reside");
      return errors;
    },
    errorCorreo() {
      const errors = [];
      if (!this.$v.correo.$dirty) return errors;
      !this.$v.correo.required && errors.push("Ingrese su correo electrónico");
      return errors;
    },
    errorTelefono() {
      const errors = [];
      if (!this.$v.telefono.$dirty) return errors;
      !this.$v.telefono.required && errors.push("Ingrese su número telefónico (9 dígitos)");
      !this.$v.telefono.minLength && errors.push("El número debe contener 9 dígitos");
      !this.$v.telefono.maxLength && errors.push("El número debe contener 9 dígitos");

      return errors;
    },
    errorNombre() {
      const errors = [];


      if (!this.$v.nombreCompleto.$dirty) return errors;
      let ns = this.nombreCompleto.trim().split(" ");
      if (ns.length < 2) {
        errors.push("Ingrese por lo menos un nombre y un apellido");
        return errors;
      }
      !this.$v.nombreCompleto.required && errors.push("Ingrese su nombre completo");
      return errors;
    },
    errorDireccion() {
      const errors = [];
      if (!this.$v.direccion.$dirty) return errors;
      !this.$v.direccion.required && errors.push("Ingrese su dirección principal");
      return errors;
    },
  },
  methods: {
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 1000))
    },

    guardarDatos() {

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.saving = true;
      this.response = null;
      this.error = null;

      const objCliente = {
        nombreCompleto: this.nombreCompleto,
        direccion: this.direccion,
        direccionAdicional: this.complementoDomicilio,
        telefono: this.telefono,
        correo: this.correo,
        comuna: this.comuna.id_comuna,
      };


      usuarioEntity.updateUsuario(objCliente)
          .then(() => {
            this.response = "Sus datos fueron cambiados con éxito";
          })
          .catch((error) => {
            this.error = error.mensaje ? error.mensaje : error;
            console.error(error);
          })
          .finally(() => {
            this.saving = false;
          })
    },
    mostrarEnMapa(addressData) {
      this.firstPlaceChanged = true;
      //ponemos el marker
      this.markerMap.lat = addressData.latitude;
      this.markerMap.lng = addressData.longitude;
      //centramos el mapa
      this.centerMap.lat = addressData.latitude;
      this.centerMap.lng = addressData.longitude;
    }
  }
}
</script>

<style scoped>

</style>
