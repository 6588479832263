<template>
  <v-container class="custom-container">
    <TheBreadcrumb/>
      <v-fade-transition mode="out-in">
        <router-view/>
      </v-fade-transition>
  </v-container>
</template>

<script>
import TheBreadcrumb from "@/components/Breadcrumb/TheBreadcrumb";
import {EventBus} from "@/main";

export default {
  name: "ClienteView",
  components: {TheBreadcrumb},
  created(){
    //si hace un logout, enviar a home
    EventBus.$on("login",(login)=>{
      if(!login)
      {
        this.$router.replace("/");
      }
    });
  },
}
</script>

<style scoped>

</style>