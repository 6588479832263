<template>
  <v-dialog max-width="500" v-model="ver">
    <v-card v-if="caratula != null">
      <v-card-text class="">


        <v-row class="px-3">
          <v-col cols="12" class="px-0 px-md-3">
            <h3 class="text-center font-weight-medium mb-4">Carátula N° {{ caratula.numero_caratula }}</h3>
            <p class="mb-3">
              <span class="font-weight-medium">N° de Solicitud: </span>{{ caratula.id_solicitud_cliente_FK }}
            </p>
            <p class="mb-3">
              <span class="font-weight-medium">Fecha de ingreso de la carátula: </span>{{
                caratula.fecha_generacion_caratula | fullDate
              }}
            </p>
            <p class="mb-3">
              <span class="font-weight-medium">Fecha de entrega estimada: </span>{{ caratula.fecha_entrega | toDate }}
            </p>
            <p class="mb-3">
              <span class="font-weight-medium">Forma de retiro: </span>
              <span v-if="caratula.id_tipo_retiro === ID_TIPO_RETIRO.PRESENCIAL">Presencial</span>
              <span v-if="caratula.id_tipo_retiro === ID_TIPO_RETIRO.VALIJA_ELECTRONICA">Valija Electrónica</span>
              <span v-if="caratula.id_tipo_retiro === ID_TIPO_RETIRO.CHILEXPRESS">Chilexpress</span>
            </p>

            <div>
              <mis-caraulas-detalle-saldo :caratula="caratula"></mis-caraulas-detalle-saldo>
            </div>

            <v-divider></v-divider>
            <div v-if="cargando_documentos_anexos">
              <p class="text-center font-weight-medium mt-3 mb-2">Verificando documentos</p>
              <v-progress-linear
                  color="blue lighten-2"
                  buffer-value="0"
                  stream
                  reverse
                  class="mb-3"
              ></v-progress-linear>
            </div>


            <div v-if="documentos_anexos.length > 0  && !cargando_documentos_anexos">

              <p class="font-weight-medium pt-3 mb-0 text-center">Documentos con firma electrónica</p>
              <lista-documentos-anexos :documentos_anexos="documentos_anexos"></lista-documentos-anexos>
            </div>


            <div v-if="documentos_anexos.length === 0 && !cargando_documentos_anexos">
              <p class="font-weight-medium font-md text-center my-3">No se encontró documentos firmados
                electrónicamente</p>
            </div>


            <v-divider></v-divider>
            <p class="font-weight-medium pt-3 text-center">Estados de la carátula</p>

            <div>
              <historial-estados-caratula :estados="caratula.estados"></historial-estados-caratula>
            </div>

          </v-col>
          <v-col cols="12" class="text-center">
            <v-btn @click="hideDialog" color="info" >Volver</v-btn>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {ID_TIPO_RETIRO} from "@/model/carro/carro.entity";
import caratulaEntity from "@/model/caratula/caratula.entity";
import MisCaraulasDetalleSaldo from "@/views/index/cliente/caratulas/MisCaraulasDetalleSaldo";
import ListaDocumentosAnexos from "@/components/DocumentosAnexos/ListaDocumentosAnexos";
import HistorialEstadosCaratula from "@/components/HistorialEstados/HistorialEstadosCaratula";

export default {
  name: "MisCaratulasModalCaratula",
  components: {MisCaraulasDetalleSaldo, ListaDocumentosAnexos, HistorialEstadosCaratula},
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    },
    caratula: {default: null}
  },
  data() {
    return {
      ver: false,
      documentos_anexos: [],
      cargando_documentos_anexos: false,
      ID_TIPO_RETIRO: ID_TIPO_RETIRO
    }
  },

  watch: {
    caratula() {
      this.documentos_anexos = [];
      this.cargando_documentos_anexos = true;
      caratulaEntity.getDocumentosAnexosCaratula(this.caratula.numero_caratula).then(resp => {
        this.documentos_anexos = resp;
        this.cargando_documentos_anexos = false;
      })
    },
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {
    hideDialog() {
      this.$emit("ocultarDialog", false);
    }
  }
}
</script>

<style scoped>

</style>
