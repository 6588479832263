<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <CambiarDatosPersonalesUsuario/>
      </v-col>
      <v-col cols="12" md="6">
        <CambiarPasswordUsuario/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CambiarDatosPersonalesUsuario from "@/components/DatosUsuario/CambiarDatosPersonalesUsuario";
import CambiarPasswordUsuario from "@/components/DatosUsuario/CambiarPasswordUsuario";

export default {
  name: "ClienteViewEditar",
  components: {CambiarPasswordUsuario, CambiarDatosPersonalesUsuario},
}
</script>

<style scoped>

</style>